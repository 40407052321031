
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';


@Component({
  selector: 'button-view',
  styleUrls: ['./edit-button.component.scss'],
  template: `
    <a href="javascript:void(0);" class="ng2-smart-action ng2-smart-action-edit-edit simplificado-action-edit ng-star-inserted" (click)="onClick()"><i class="nb-edit"></i></a>
  `,
})
export class EditButtonComponent implements ViewCell, OnInit {
  renderValue: string;

  @Input() value: string | number;
  @Input() rowData: any;

  @Output() save: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    this.renderValue = this.value.toString().toUpperCase();
  }

  onClick() {
    this.save.emit(this.rowData);
  }
}
