import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { NgHttpService } from '../../../services/httpservice'

@Injectable()
export class PerfilAcessoService {
  constructor(private _http: NgHttpService) { }

  add(data: any): Observable<any> {
    data.tipo = 1
    return this._http.post(`Perfil/Adicionar`, data)
  }

  alterar(user: any): Observable<any> {
    return this._http.put(`Perfil/Alterar`, user)
  }

  listar(body: any = { ativo: 0 }): Observable<any> {
    return this._http.get(`Perfil/listar`, body)
  }

  listarPorId(id: string): Observable<any> {
    const url = `Perfil/ListarPorId/${id}`;
    return this._http.get(url, {});
  }

  excluir(id: string): Observable<any> {
    const url = `Perfil/Excluir/${id}`;
    return this._http.put(url, {});
  }
}
