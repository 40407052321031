import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'currencyFormat' })
export class CurrencyFormatPipe implements PipeTransform {
  transform(
    value: number = 0,
    locale: string,
    currency_symbol: boolean = false,
    number_format: string = '1.2-2'
  ): string {
    let currencyPipe = new CurrencyPipe('en');
    let new_value: string;

    new_value = currencyPipe.transform(
      value || 0,
      locale,
      currency_symbol,
      number_format
    );
    new_value = new_value
      .replace('.', '|')
      .replace(',', '.')
      .replace('|', ',')
      .replace('USD', '');

    return new_value;
  }
}
