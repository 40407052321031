import { ModuleWithProviders, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgxCurrencyModule, CurrencyMaskInputMode } from 'ngx-currency'
import {
  SimplDatePickerDirective
} from '../@core/directives/index';

import {
  CurrencyMaskConfig,
  CURRENCY_MASK_CONFIG
} from 'ngx-currency';


import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
} from '@nebular/theme'
import { NbEvaIconsModule } from '@nebular/eva-icons'
import { NbSecurityModule } from '@nebular/security'

import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  SwitcherComponent,
  TinyMCEComponent,
} from './components'
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  CurrencyFormatPipe,
  DateFormatPipe,
} from './pipes'
import {
  OneColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  WithoutColumnLayoutComponent,

} from './layouts'
import { DEFAULT_THEME } from './styles/theme.default'
import { COSMIC_THEME } from './styles/theme.cosmic'
import { CORPORATE_THEME } from './styles/theme.corporate'
import { DARK_THEME } from './styles/theme.dark'
import { EditButtonComponent } from './components/buttons/edit-button/edit-button.component'
import { FormsModule } from '@angular/forms'
import { NgSelectModule } from '@ng-select/ng-select'
import { TextMaskModule } from 'angular2-text-mask'

import { FileUploadModule } from 'ng2-file-upload'
import { EntidadeService } from '../pages/cadastros/clientes/clientes.service'
import { TagsComponent } from '../shared/tag-component/tags/tags.component';
import { TagComponent } from '../shared/tag-component/tag/tag.component';
import { TagFilterPipe } from '../shared/tag-component/tag-filter.pipe';
import { SuggestionComponent } from '../shared/tag-component/suggestion/suggestion.component';
import { TagService } from '../shared/tag-component/tag.service';

const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'R$ ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
}

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  FormsModule,
  NgSelectModule,
]

const COMPONENTS = [
  SwitcherComponent,
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  WithoutColumnLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  SimplDatePickerDirective,
  TagsComponent, 
  TagComponent, 
  TagFilterPipe, 
  SuggestionComponent
]

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  CurrencyFormatPipe,
  DateFormatPipe
]

const BASE_MODULES = [
  CommonModule,
  FormsModule,
  TextMaskModule,
  NgSelectModule,
  FileUploadModule,
]

const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: 'left',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: '',
  suffix: '',
  thousands: '.',
  nullable: true
};

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: 'default'
    },
    [DEFAULT_THEME]
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
];

@NgModule({
  imports: [
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    ...BASE_MODULES,
    ...NB_MODULES,
  ],
  exports: [...BASE_MODULES, ...PIPES, ...COMPONENTS],
  declarations: [...COMPONENTS, ...PIPES, EditButtonComponent],
  providers: [EntidadeService, TagService],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME]
        ).providers,
      ],
    }
  }
}


