import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { NgHttpService } from '../../../services/httpservice'


@Injectable()
export class EntidadeService {
  constructor(private _http: NgHttpService) { }

  add(data: any): Observable<any> {
    data.tipo = 1
    return this._http.post(`Entidades/Adicionar`, data)
  }

  alterar(user: any): Observable<any> {
    return this._http.put(`Entidades/Alterar`, user)
  }

  listar(data: any): Observable<any> {
    data.tipo = 1
    return this._http.post(`Entidades/Listar`, data)
  }

  listarFiltro(data: any): Observable<any> {
    data.tipo = 1
    return this._http.post(`Entidades/ListarFiltro`, data)
  }

  listarPorId(params: any): Observable<any> {
    return this._http.get(`Entidades/ListarPorId`, params)
  }

  salvarCpfCnpj(params: any): Observable<any> {
    return this._http.put(`Entidades/SalvarCpfCnpj`, params)
  }

  excluir(id: string): Observable<any> {
    const url = `Entidades/Excluir/${id}`;
    return this._http.put(url, {});
  }

  liberarAcesso(id: string): Observable<any> {
    return this._http.get(`AcessoExterno/LiberarAcessoExterno`, { id })
  }

  removerAcesso(id: string): Observable<any> {
    return this._http.get(`AcessoExterno/RemoverAcessoExterno`, { id })
  }

  excluirDocumento(id: string): Observable<any> {
    return this._http.delete(`Entidades/ExcluirDocumento`, { id })
  }

  excluirFilial(id: string): Observable<any> {
    return this._http.delete(`Entidades/ExcluirFilial`, { id })
  }

  alterarEmpresa(body: any): Observable<any> {
    return this._http.post(`Clientes/AlterarEmpresa`, body)
  }

  adicionarFilial(body: any): Observable<any> {
    return this._http.post(`Cliente/AdicionarFilial`, body)
  }

  listarFiliais(params: any = {}): Observable<any> {
    return this._http.get(`Clientes/ListarFiliais`, params)
  }

  adicionarTelefoneEmail(data: any = {}) {
    return this._http.post(`Entidades/AdicionarTelefoneEmail`, data)
  }

  adicionarEndereco(data: any = {}) {
    return this._http.post(`Entidades/AdicionarEndereco`, data)
  }

  addEmail(data: any): Observable<any> {
    return this._http.post(`EntidadesEmail/Adicionar`, data)
  }

  listarEmail(entidadeId: string): Observable<any> {
    const url = `EntidadesEmail/Listar/${entidadeId}`;
    return this._http.post(url, {});
  }

  excluirEmail(id: string): Observable<any> {
    const url = `EntidadesEmail/Excluir/${id}`;
    return this._http.put(url, {});
  }

}
