<div class="app-tags-container">
    <div class="app-tags" [class.expanded]="expanded" (click)="onExpand()">
      <app-tag
        *ngFor="let tag of tags"
        [tag]="tag"
        (closed)="tagRemoved.emit($event)"
      ></app-tag>
    </div>
    <div class="col-sm-2 app-tag-input-wrapper">
      <ng-select 
      type="text"
      [typeahead]="tagsAutocomplete"
      (keyup)="onKeyUp($event)"
      (change)="onSelectionChange($event)"
      placeholder="Adicionar marcador ..."
      typeToSearchText="Digite para buscar"
      notFoundText="Nenhum registro encontrado"
      [items]="listaTags"
    >
    </ng-select>
      <div class="add-btn" (click)="onAdd()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
          <path
            d="M16 0C7.2 0 0 7.2 0 16s7.2 16 16 16 16-7.2 16-16S24.8 0 16 0zm7 17h-6v6h-2v-6H9v-2h6V9h2v6h6v2z"
            fill="#0091dc"
          />
        </svg>
      </div>
      <div
        class="app-tag-suggestions"
        *ngIf="(suggestions | tagFilter: tags:debouncedText).length > 0"
      >
        <app-suggestion
          *ngFor="let suggestion of suggestions | tagFilter: tags:debouncedText"
          [suggestion]="suggestion"
          (closed)="tagAdded.emit($event)"
        ></app-suggestion>
      </div>
    </div>
  </div>
  