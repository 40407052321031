<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>


    <div class="logo" (click)="navigateHome()">
      <img [src]="'assets/images/logo_h.png'" alt="Simplificado" />
      <!-- <button class="btn btn-info" routerLink="/app/pagamento">Assine Já</button>-->
    </div>
  </div>

</div>

<div class="header-container">

  <a (click)="empresaCnpj(dialogEmpresa, empresas[0])" class="action-empresa" title="Empresas">
    <nb-icon icon="search" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
  </a>


  <div class="cnpj">
    <ng-select [items]="empresas" placeholder="Selecione uma empresa" bindLabel="nome"
      notFoundText="Nenhum registro encontrado" bindValue="id" name="empresas" [clearable]="false"
      class="select-estados" [(ngModel)]="user.clienteId" (ngModelChange)="onChangeEmpresa()">
      <ng-template ng-label-tmp let-item="item">
        {{ item.cpfCnpjFormatado }} - {{ item.nome }}
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        {{ item.cpfCnpjFormatado }} - {{ item.nome }}
      </ng-template>
    </ng-select>
  </div>



  <ng-template #dialogEmpresa let-data let-ref="dialogRef">
    <nb-card-header>Mudar de Empresa</nb-card-header>
    <nb-card-body>
      <ng-select [items]="empresas" placeholder="Selecione uma empresa" bindLabel="nome"
        notFoundText="Nenhum registro encontrado" bindValue="id" name="empresas" [clearable]="false"
        class="select-estados" [(ngModel)]="user.clienteId" (ngModelChange)="onChangeEmpresa()">
        <ng-template ng-label-tmp let-item="item">
          {{ item.cpfCnpjFormatado }} - {{ item.nome }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          {{ item.cpfCnpjFormatado }} - {{ item.nome }}
        </ng-template>
      </ng-select>
      <button class="btn btn-secondary" (click)="ref.close()">Fechar</button>
    </nb-card-body>
  </ng-template>

  <!---------------->

  <div class="user">
    <nb-actions size="small">
      <nb-action class="user-action" *nbIsGranted="['view', 'user']">
        <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.nome"
          [picture]="user?.picture">
        </nb-user>
      </nb-action>
    </nb-actions>
  </div>


  <!-- <button class="icon-button" [nbContextMenu]="userMenu">
    <i class="person fas fa-user"></i>
  </button> -->

  <a [nbContextMenu]="userMenu" class="action-user" title="Usuario">
    <nb-icon icon="person" [options]="{ animation: { type: 'zoom' } }"></nb-icon>
  </a>
</div>