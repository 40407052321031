import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-switcher',
  styleUrls: ['./switcher.component.scss'],
  template: `
    <label class="switch-label">
      <div class="switch" [class.active]="isSecondValue()">
        <input [disabled]="disabled" type="checkbox" [checked]="isSecondValue()" (change)="changeValue()">
        <span class="slider"></span>
      </div>
    </label>
  `
})
export class SwitcherComponent {
  @Input()
  firstValue: any;
  @Input()
  secondValue: any;

  @Input()
  vertical: boolean;

  @Input()
  value: any;

  @Input()
  disabled: boolean;

  @Output()
  valueChange = new EventEmitter<any>();

  constructor() {
    this.firstValue = false;
    this.secondValue = true;
  }

  isFirstValue() {
    return this.value === this.firstValue;
  }

  isSecondValue() {
    return this.value === this.secondValue;
  }

  changeValue() {
    this.value = !!this.value;

    this.value = this.isFirstValue() ? this.secondValue : this.firstValue;
    this.valueChange.emit(this.value);
  }
}
