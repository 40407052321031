import { Component } from '@angular/core';

@Component({
  selector: 'ngx-without-column-layout',
  styleUrls: ['./without-column.layout.scss'],
  template: `
     <ng-content select="router-outlet"></ng-content>
  `,
})
export class WithoutColumnLayoutComponent { }
