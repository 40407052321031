import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import 'rxjs/add/observable/throw'
import { environment } from '../../environments/environment'
import { Router } from '@angular/router'
import { NbToastrService } from '@nebular/theme'

@Injectable({
  providedIn: 'root',
})
export class NgHttpService {
  private headers = {}

  constructor(
    private _http: HttpClient,
    private router: Router,
    private _toastr: NbToastrService
  ) { }

  get(endpoint: string, params: any): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}/api/${endpoint}`, {
        params,
        headers: {
          ...this.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(catchError((error) => this._handleError(error)))
  }

  postReport(endpoint: string, body: any): Observable<any> {
    return this._http
      .post(`${environment.apiReportUrl}/api/${endpoint}`, body, {
        headers: {
          ...this.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },

        responseType: 'blob' as 'json',
      })
      .pipe(catchError((error) => this._handleError(error)))
  }

  post(endpoint: string, body: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}/api/${endpoint}`, body, {
        headers: {
          ...this.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(catchError((error) => this._handleError(error)))
  }

  put(endpoint: string, body: any): Observable<any> {
    return this._http
      .put(`${environment.apiUrl}/api/${endpoint}`, body, {
        headers: {
          ...this.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(catchError((error) => this._handleError(error)))
  }

  delete(endpoint: string, params: any): Observable<any> {
    return this._http
      .delete(`${environment.apiUrl}/api/${endpoint}?id=${params.id}`, {
        headers: {
          ...this.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(catchError((error) => this._handleError(error)))
  }

  upload(endpoint: string, formData: FormData): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}/api/${endpoint}`, formData, {
        headers: {
          ...this.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },
      })
      .pipe(catchError((error) => this._handleError(error)))
  }

  getBlob(endpoint: string, body: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}/api/${endpoint}`, body, {
        headers: {
          ...this.headers,
          Authorization: `Bearer ${this.getToken()}`,
        },
        observe: 'response',
        responseType: 'blob' as 'json',
      })
      .pipe(catchError((error) => this._handleError(error)))
  }

  getToken(): string {
    return localStorage.getItem('access_token')
  }

  private _handleError(response: any) {
    console.log(response)
    if (response.status === 401) {
      localStorage.clear()
      this._toastr.info(
        'Sua sessão expirou, por favor, faça o login novamente',
        'Oops'
      )
      this.router.navigate(['/auth/login'])

      return Observable.throw('Token expirou')
    }
    const { error } = response

    if (error != null && error.errors && error.errors instanceof Array) {
      error.errors.forEach((item) => {
        this._toastr.danger(item.message, 'Oops, ocorreu um erro')
      })

      return Observable.throw(error.errors)
    } else if (error != null && error.errors) {
      this._toastr.danger(
        error.errors ||
        'Houve um problema interno com o servidor. Entre em contato com o Administrador do sistema caso o problema persista',
        error.errors ? '' : 'Ocorreu um erro'
      )
    } else {
      this._toastr.danger(
        'Houve um problema interno com o servidor. Entre em contato com o Administrador do sistema caso o problema persista',
        'Ocorreu um erro'
      )
    }
    return Observable.throw('Erro interno.')
  }
}
