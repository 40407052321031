import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { TagService } from '../tag.service';
import { Tag } from '../tag';
import { UtilsService } from '../../../@core/data/utils.service';

@Component({
  selector: 'app-tags',
  templateUrl: 'tags.component.html',
  styleUrls: ['tags.component.scss'],
})
export class TagsComponent implements OnInit, OnDestroy {

  @Input() tags: Tag[];
  @Input() suggestions: Tag[];

  @Output() tagAdded = new EventEmitter<Tag>();
  @Output() tagRemoved = new EventEmitter<Tag>();

  @ViewChild('tagInput') tagInput: NgModel;

  tagsAutocomplete = new EventEmitter<string>()

  expanded = true;
  tagInputText = '';

  debouncedText = '';

  listaTags: any[] = []

  private changesSub: Subscription;

  constructor(
    private tagService: TagService,
    private _utilsService: UtilsService,
    private cd: ChangeDetectorRef
    ,) { }

  ngOnInit() {
    this.carregaTags()

    this.changesSub = this.tagInput.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(tagInputText => {
        this.debouncedText = tagInputText;
      });
  }

  carregaTags() {
    this.tagsAutocomplete
      .pipe(
        debounceTime(200),
        switchMap((busca) =>
          this._utilsService.listarTags({ busca })
        )
      )
      .subscribe((data) => {
        this.listaTags = data.result
        this.cd.markForCheck()
      })
  }

  ngOnDestroy() {
    if (this.changesSub) {
      this.changesSub.unsubscribe();
    }
  }

  onKeyUp(event: KeyboardEvent) {
    this.tagInputText = (event.target as HTMLInputElement).value.trim();
  }

  onSelectionChange(selectedItem: any) {
    this.tagInputText = selectedItem;
  }

  saveTag(tagValue: string) {
    if (tagValue !== '') {

      this.tagAdded.emit({ name: tagValue, backgroundColor: '#868E96', color: '#FFFFFF' });
      this.tagInput.reset();
    }
  }

  onAdd() {
    this.saveTag(this.tagInputText);
    this.tagInputText = ''; // Limpa o texto após salvar
    // Resto do código onAdd
  }

  onExpand() {
    this.expanded = !this.expanded;
  }
}
