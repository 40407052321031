import { Directive, ElementRef } from '@angular/core';

import VMasker from 'vanilla-masker';
import { NgModel, NgControl } from '@angular/forms';
import $ from 'jquery';
import 'bootstrap-datepicker';

@Directive({
  selector: '[simplDatePicker]',
  providers: [NgModel]
})
export class SimplDatePickerDirective {
  constructor(
    private el: ElementRef,
    private ngModel: NgModel,
    private ngControl: NgControl
  ) {
    VMasker(el.nativeElement).maskPattern('99/99/9999');
    $.fn.datepicker.dates['pt-BR'] = {
      days: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado'
      ],
      daysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      daysMin: ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa'],
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro'
      ],
      monthsShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ],
      today: 'Hoje',
      monthsTitle: 'Meses',
      clear: 'Limpar',
      format: 'dd/mm/yyyy'
    };
    const value = el.nativeElement.value;

    $(el.nativeElement)
      .datepicker({
        language: 'pt-BR',
        autoclose: true,
        clearBtn: true,
        todayHighlight: true,
        startDate: '01/01/2000'
      })
      .on('changeDate', e => {
        this.ngControl.control.setValue(e.target.value);
      });
  }
}
