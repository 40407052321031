import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core'
import {
  NbMediaBreakpointsService,
  NbMenuService,
  NbSidebarService,
  NbThemeService,
} from '@nebular/theme'
import { NbDialogService } from '@nebular/theme'

import { LayoutService } from '../../../@core/utils'
import { Subject } from 'rxjs'
import { AuthService } from '../../../services/auth.services'
import { Router } from '@angular/router'
import { EntidadeService } from '../../../pages/cadastros/clientes/clientes.service'
import { takeUntil } from 'rxjs/operators'
import { PerfilAcessoService } from '../../../pages/cadastros/perfil-acesso/perfil-acesso.service'
import _Swal from 'sweetalert2';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  providers: [PerfilAcessoService],
})
export class HeaderComponent implements OnInit, OnDestroy {
  empresas: any = []
  private destroy$: Subject<void> = new Subject<void>()
  userPictureOnly: boolean = false
  user: any
  currentTheme = 'default'
  showEmpresaSelector: boolean = false;
  rowEmpresa: any
  loading: boolean

  userMenu = []

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private layoutService: LayoutService,
    private _authService: AuthService,
    private breakpointService: NbMediaBreakpointsService,
    private _entidadeService: EntidadeService,
    private _router: Router,
    private perfil: PerfilAcessoService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit() {
    const usr = this._authService.getUserLogged()
    if (usr) {
      this.user = usr.result

      this.perfil.listarPorId(this.user.perfilId).subscribe((response) => {
        if (
          response.result.nome == 'Admin' ||
          response.result.nome == 'Administrador'
        ) {
          this.userMenu = [
            {
              title: 'Empresas',
              link: 'pages/empresa/index',
            },
            {
              title: 'Configurações',
              link: 'pages/configuracoes/editar',
            },
            {
              title: 'Usuários',
              link: 'pages/usuarios/index',
            },
            { title: 'Trocar Senha', link: 'pages/usuarios/trocar-senha' },
            {
              title: 'Perfil Acesso',
              link: 'pages/perfil-acesso/index',
            },
            {
              title: 'Controle Acesso',
              link: 'pages/controle-acesso/editar',
            },
            { title: 'Sair', link: 'auth/register' },
          ]
        } else {
          this.userMenu = [
            { title: 'Trocar Senha', link: 'pages/usuarios/trocar-senha' },
            { title: 'Sair', link: 'auth/register' },
          ]
        }
      })

      this._entidadeService.listarFiliais().subscribe((data) => {
        this.empresas = data.result
      })

      this.sidebarService.collapse()
    } else {
      this._router.navigate(['auth/login'])
    }

    const { sm } = this.breakpointService.getBreakpointsMap()
    this.menuService
      .onItemSelect()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: { tag: string; item: any }) => {
        this.sidebarService.collapse('menu-sidebar')
        this.layoutService.changeLayoutSize()
      })
  }

  ngOnDestroy() {
    this.destroy$.next()
    this.destroy$.complete()
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar')
    this.layoutService.changeLayoutSize()

    return false
  }

  navigateHome() {
    this.menuService.navigateHome()
    return false
  }

  onChangeEmpresa() {
    const request = {
      id: this.user.clienteId
    };

    this._entidadeService
      .alterarEmpresa(request)
        .subscribe((data) => {
          const user = {
            result: this.user
          }

          localStorage.setItem('userLogged', JSON.stringify(user));

          localStorage.removeItem('access_token');
          localStorage.setItem('access_token', data.result);
        
          window.location.reload();
        });
  }


  empresaCnpj(dialog: TemplateRef<any>, empresa: any) {
    this.loading = true;
    this.rowEmpresa = empresa.Empresa;
    this.loading = false;

    // Exibir o diálogo e a seção ao clicar no ícone
    this.dialogService.open(dialog);
    this.showEmpresaSelector = true;
  }

}
