import { of as observableOf, Observable } from 'rxjs'
import { Injectable } from '@angular/core'
import { NgHttpService } from '../../services/httpservice'
//import { NgxHttpService } from '../http/http.service'
//import * as cpf from '@fnando/cpf'
//import * as cnpj from '@fnando/cnpj'

@Injectable()
export class UtilsService {
  constructor(private _http: NgHttpService) { }

  verificarTipoCliente(data: any): Observable<any> {
    return this._http.get(`Clientes/VerificarTipoCliente`, data);
  }

  addTag(data: any): Observable<any> {
    return this._http.post(`Tags/Adicionar`, data);
  }

  excluirTags(nome: string, id: string): Observable<any> {
    const url = `Tags/Excluir/${nome}/${id}`;
    return this._http.put(url, {});
  }

  listarTags(busca: any = {}): Observable<any> {
    return this._http.post('Tags/Listar', busca)
  }

  listarPorIdTags(IdTela: any): Observable<any> {
    return this._http.get(`Tags/ListarPorId/${IdTela}`, {});
  }

  getEstados(params: any = {}): Observable<any> {
    return this._http.get('Estados/Listar', params)
  }

  getEstadosPorUF(params: any = {}): Observable<any> {
    return this._http.get('Estados/ListarPorUF', params)
  }

  getCidades(params: any = {}): Observable<any> {
    return this._http.get('Cidades/Listar', params)
  }

  getCidadesPorNome(params: any = {}): Observable<any> {
    return this._http.get('Cidade/ListarPorNome', params)
  }

  getRamoAtividade(params: any = {}): Observable<any> {
    return this._http.get('RamoAtividade/Listar', params)
  }

  getBancos(params: any = {}): Observable<any> {
    return this._http.get('Bancos/Listar', params)
  }

  getBancosPorId(params: any = {}): Observable<any> {
    return this._http.get(`Bancos/ListarPorId`, params)
  }

  getCarteira(params: any = {}): Observable<any> {
    return this._http.get('Bancos/ListarCarteira', params)
  }

  getNCM(params: any = { desc: '' }): Observable<any> {
    return this._http.get('Ncm/Listar', params)
  }

  getCFOP(params: any = { desc: '' }): Observable<any> {
    return this._http.get('CFOP/Listar', params)
  }

  getCEST(params: any = { desc: '' }): Observable<any> {
    return this._http.get('CEST/Listar', params)
  }

  getCENQ(params: any = { desc: '' }): Observable<any> {
    return this._http.get('CENQ/Listar', params)
  }

  getEntidade(params: any = {}): Observable<any> {
    return this._http.post('Entidades/Listar', params)
  }

  getEntidadeEnderecos(params: any = {}): Observable<any> {
    return this._http.get('Entidades/BuscarEndereco', params)
  }

  getMotoristasAprovados(data: any): Observable<any> {
    return this._http.get(`MotoristaAcessoExterno/ListarPorAprovado`, data)
  }

  getIsoContainer(params: any = {}): Observable<any> {
    return this._http.post('Terminal/ContainerIso/Listar', params)
  }

  getClientes(params: any = {}): Observable<any> {
    return this._http.post('Entidades/Listar', params)
  }

  getEntidadePorId(params: any = {}): Observable<any> {
    return this._http.get('Entidade/ListarPorId', params)
  }

  getEntidadesPorId(params: any = {}): Observable<any> {
    return this._http.get('Entidades/ListarPorId', params)
  }

  getMotoristaPorCpf(params: any): Observable<any> {
    return this._http.post(`Terminal/Motorista/Buscar`,params)
  }

  getFirstDayOfMonth(month: number = new Date().getMonth() + 1): any {
    const year = new Date().getFullYear()

    return `${('0' + 1).slice(-2)}/${('0' + month).slice(-2)}/${year}`
  }

  getLastDayOfMonth(month: number = new Date().getMonth() + 1): any {
    const year = new Date().getFullYear()
    const day = new Date(year, month, 0).getDate()

    return `${('0' + day).slice(-2)}/${('0' + month).slice(-2)}/${year}`
  }

  getDateFormattedFromDatepicker(date: any) {
    if (!date) return null
    const [day, month, year] = date.split('/')

    return new Date(year, month - 1, day)
  }

  getDateFormattedToDatepicker(timestamp: string) {
    if (!timestamp) return null

    const date = new Date(timestamp)
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()

    return `${('0' + day).slice(-2)}/${('0' + month).slice(-2)}/${year}`
  }

  getDateFormattedFromAPI(timestamp) {
    if (!timestamp) return null

    const date = new Date(timestamp)
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()

    return `${('0' + day).slice(-2)}/${('0' + month).slice(-2)}/${year}`
  }

  getActualDateFormattedToDatepicker() {
    const date = new Date()

    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()

    return `${('0' + day).slice(-2)}/${('0' + month).slice(-2)}/${year}`
  }

  getTipoTelefone(): Array<any> {
    return [
      { tipo: 0, nome: 'Casa' },
      { tipo: 1, nome: 'Comercial' },
      { tipo: 2, nome: 'Fax' },
      { tipo: 3, nome: 'Celular' }
    ]
  }

  isObjectEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false
    }
    return true
  }

  getEnderecoCorreios(query: string) {
    const cep = query.replace(/\D/, '')

    return this._http
      .post('Clientes/ConsultaCep', { cep })
      .toPromise()
      .then((endereco: any) => {
        delete endereco.complemento
        if (endereco.erro) return null
        console.log(endereco)
        return this.getCidades({ estadoId: endereco.result.estadoId })
          .toPromise()
          .then(response => {
            return { ...endereco.result, cidades: response }
          })
      })
  }

  isCNPJValid(cpnjString: string) {
    return true//cnpj.isValid(cpnjString)
  }

  isCPFValid(cpfString: string) {
    return true;//cpf.isValid(cpfString)
  }

  abrirArquivo(params: any): Observable<any> {
    return this._http.get(`Terminal/ContainerColeta/AbrirArquivo`, params);
  }

  retornaTipoCliente(params: any): Observable<any> {
    return this._http.get(`Clientes/RetornaTipoCliente`, params);
  }
}
